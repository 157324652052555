@use "@angular/material" as mat;
@use "@ciphr/material" as ciphr-mat;
@use "@ciphr/styles" as ciphr;

@import "styles/themes/light-theme";

@include mat.core();
@include mat.core-theme($my-pay-light-theme);

@import "styles/material/components";

:root {
  --loading-spinner-overlay-background: rgba(255, 255, 255, 0.5);
  --mat-menu-container-color: #fff;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  @include mat.typography-level(ciphr-mat.$mypay-typography, "body-1");
  margin: 0;
}

.page-container {
  display: block;

  margin: 0 auto;
  padding: 0;

  max-width: 90rem;
  width: 100%;
}

ciphr-auth-context-selector {
  @media (max-width: 375px) {
    width: 150px;
  }

  .mat-mdc-form-field {
    width: 80% !important;

    @include ciphr.respond-to-media(web) {
      width: 100% !important;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;

    @include ciphr.respond-to-media(web) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.option .mat-pseudo-checkbox {
  display: none;
}

.mat-mdc-snack-bar-container {
  &.material-snackbar {
    --mdc-snackbar-container-color: #fff;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #000;
  }
}

.mat-mdc-standard-chip .mdc-evolution-chip__icon--trailing {
  font-size: 14px !important;
  height: 14px !important;
  width: 14px !important;
}
